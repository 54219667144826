/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $('#mobileToggle').on('click', function() {
          $navPrimary = $('#navPrimary');
          if ( $navPrimary.attr('data-status') === 'active' ) {
            $navPrimary.attr('data-status', 'inactive');
            $(this).attr('data-status', '');
          } else {
            $navPrimary.attr('data-status', 'active');
            $(this).attr('data-status', 'active');
          }
        });

        $(window).on('resize', function() {
          $('#navPrimary').attr('data-status', '');
          $('#mobileToggle').attr('data-status', '');
        });

        $(window).scroll(function() {
          if ( window.pageYOffset > 0 ) {
            $('#siteBanner').attr('data-status', 'sticky');
          } else {
            $('#siteBanner').attr('data-status', '');
          }
        });

        if ( $('.nav-tour').length ) {
          $(window).scroll(function() {
            $.each( $('.nav-tour a'), function(index, value) {
              href = $(value).attr('href');
              $href = $(href);
              if ( window.pageYOffset > ($href.offset().top - '105') ) {
                $('.nav-tour a').removeClass('active');
                $(value).addClass('active');
              }
            });
          });
        }

        $('.nav-tour a').on('click', function() {
          if( $('#navPrimary').attr('data-status') === 'active' ) {
            $('#navPrimary').attr('data-status', 'inactive');
            $('#mobileToggle').attr('data-status', 'inactive');
          }
        });

        $('.tours-template-default a[href*="#"]:not([href="#"])').click(function() {
          if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
              $('html, body').animate({
                scrollTop: target.offset().top - '100'
              }, 600);
              return false;
            }
          }
        });

        // Responsive Video script
        var iframes = document.getElementsByTagName( 'iframe' );

        for ( var i = 0; i < iframes.length; i++ ) {

          var iframe = iframes[i];
          var players = /www.youtube.com|player.vimeo.com/;

          // If the RegExp pattern exists within the current iframe
          if ( iframe.src.search( players ) > 0 ) {

            // Calculate the video ratio based on the iframe's w/h dimensions
            var videoRatio = ( iframe.height / iframe.width ) * 100;

            // Replace the iframe's dimensions and position
            iframe.style.position = 'absolute';
            iframe.width          = '100%';
            iframe.height         = '100%';
            iframe.style.top      = '0';
            iframe.style.left     = '0';

            // Wrap the iframe in a new, repsonsive <div>
            var wrap              = document.createElement( 'div' );
            wrap.className        = 'fluid-video';
            wrap.style.width      = '100%';
            wrap.style.paddingTop = 'calc(' + videoRatio + '% - 2px)';

            if ( iframe.parentNode.classList.contains('hero-bgvideo')) {
              wrap.style.position   = 'absolute';
              wrap.style.top        = '50%';
              wrap.style.left       = '50%';
              wrap.style.transform  = 'translate(-50%, -50%)';

              if ( iframe.offsetHeight <= document.getElementById('top').offsetHeight ) {
                wrap.style.minHeight  = document.getElementById('top').offsetHeight + 'px';
                wrap.style.height     = document.getElementById('top').offsetHeight + 'px';
                wrap.style.width      = videoRatio * document.getElementById('top').offsetHeight + 'px';
              }

              function fadeIn(el) {
                var opacity = 0;

                el.style.opacity = 0
                el.style.filter = ''

                var last = +new Date
                var tick = function() {
                  opacity += (new Date - last) / 400
                  el.style.opacity = opacity
                  el.style.filter = 'alpha(opacity=' + (100 * opacity)|0 + ')'

                  last = +new Date

                  if (opacity < 1)
                    (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16)
                }

                tick()
              }

              wrap.style.opacity   = 0;
              setTimeout(function() {
                fadeIn(wrap);
              }, 3500);

              window.addEventListener('resize', function() {
                console.log('resize');

                wrap.style.minHeight  = document.getElementById('top').offsetHeight + 'px';
                wrap.style.height     = document.getElementById('top').offsetHeight + 'px';
                wrap.style.width      = videoRatio * document.getElementById('top').offsetHeight + 'px';
              }, false)
            } else {
              wrap.style.position     = 'relative';
              iframe.style.position   = 'absolute';
            }

            // Add the iframe inside our responsive <div>
            var iframeParent      = iframe.parentNode;
            iframeParent.insertBefore( wrap, iframe );
            wrap.appendChild( iframe );
          }
        }// END for loop
        // END Responsive Video script
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
